import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import ContentBlock from "../components/ContentBlock"
import { ReactComponent as DrivenByPerformance } from "../icons/driven-by-performance.svg"
import { ReactComponent as WithoutWalls } from "../icons/your-office-without-walls.svg"
import { ReactComponent as Stopwatch } from "../icons/stopwatch.svg"
import { ReactComponent as ImportBusiness } from "../icons/cloud-monitor-upload.svg"
import { ReactComponent as DispatchCall } from "../icons/car-repair-tow-truck-1.svg"
import { ReactComponent as Icon247 } from "../icons/247.svg"
import { Link } from "gatsby"
import { featureGroups } from "../data/features"
import { ReactComponent as AngleRight } from "../icons/angle-right.svg"
import { useTrail, useSpring, animated } from "react-spring"
import { Waypoint } from "react-waypoint"

const IndexFeatureRow = ({ index, name, description, media }) => {
  let [featureVisible, featureVisibleToggle] = useState(false)
  let featureSpring = useSpring({
    opacity: featureVisible ? 1 : 0,
    transform: featureVisible
      ? "translate3d(0,0,0)"
      : `translate3d(${index % 2 ? "50%" : "-50%"},0,0)`,
    config: {
      tension: 120,
      friction: 15,
    },
  })
  return (
    <>
      <animated.div
        style={featureSpring}
        className={`flex flex-col-reverse mb-16 lg:mb-24 lg:items-center ${
          index % 2 ? "lg:flex-row-reverse" : "lg:flex-row"
        }`}
      >
        <div className="lg:w-2/5">
          <div className="text-xl lg:text-2xl font-500 leading-300">{name}</div>
          <div className="mt-2 text-gray-200 lg:text-lg">{description}</div>
        </div>
        <div
          className={`lg:w-3/5 mb-6 lg:mb-0 ${
            index % 2 ? "lg:pr-12" : "lg:pl-12"
          }`}
        >
          <img src={media} alt={name} className="rounded" />
        </div>
      </animated.div>
      <Waypoint
        onEnter={() => featureVisibleToggle(true)}
        bottomOffset="-10%"
      ></Waypoint>
    </>
  )
}

IndexFeatureRow.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  media: PropTypes.string,
}

const PageIndex = () => {
  let [stepsVisible, stepsToggle] = useState(false)

  let stepsTrail = useTrail(3, {
    opacity: stepsVisible ? 1 : 0,
    transform: stepsVisible ? "translate3d(0,0,0)" : "translate3d(-50%,0,0)",
    config: {
      tension: 120,
      friction: 15,
    },
  })

  return (
    <Layout>
      <ContentBlock className="flex flex-col-reverse items-stretch pb-12 md:flex-row md:items-center md:pt-4 md:pb-16 lg:pt-20 lg:pb-20">
        <div className="mt-6 lg:mt-0 md:w-1/2 lg:w-1/2">
          <h2 className="text-3xl text-gray-100 leading-300 lg:leading-300 lg:text-5xl font-300 lg:font-200">
            The Most Trusted Towing Software,
            <span className="font-400"> Period.</span>
          </h2>
          <p className="mt-2 text-base text-gray-300 leading-500 lg:text-xl">
            Towbook is the unquestioned leader for cloud-based towing software.
            Our towing, roadside and impound software is your all-encompassing
            solution for Private Property, Police Calls, Transport, Local Calls,
            Motor Clubs, and more!
          </p>
          <div className="flex justify-center md:justify-start">
            <Link
              to="/signup/"
              className="inline-block px-6 py-3 mt-6 text-lg text-gray-800 bg-yellow-500 rounded-lg hover:bg-yellow-400 font-400"
            >
              Get Started Now!
            </Link>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 md:ml-8 lg:ml-12">
          <div className="aspect-16/9">
            <iframe
              src="https://player.vimeo.com/video/798757513?h=3d1ed316f4&color=0177cb&title=0&byline=0&portrait=0"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Towbook Overview Video"
            ></iframe>
          </div>
        </div>
      </ContentBlock>
      <ContentBlock className="relative -mb-8 md:-mb-16 lg:-mb-20">
        <img
          src="/screenshots/powerful-mobile-apps.png"
          alt="Towbook mobile app on mobile devices"
          className="w-full max-w-5xl mx-auto"
        />
      </ContentBlock>
      <ContentBlock containerClassName="bg-gray-100 text-gray-800 pt-12 md:pt-24 lg:pt-32 pb-8 lg:pb-16">
        <div className="max-w-4xl mx-auto text-3xl text-center lg:text-5xl font-700 leading-300">
          3 Simple Steps to Amplify Your Business with the Power of Towbook
        </div>

        <div className="flex flex-col mt-16 text-sm lg:flex-row lg:text-base">
          <div className="flex-1">
            <animated.div style={stepsTrail[0]}>
              <Stopwatch className="w-16 h-16 mb-4 text-blue-500 lg:mb-8 lg:mx-auto lg:w-24 lg:h-24"></Stopwatch>
              <div className="text-xl font-600">
                Sign up for a 30-day free trial
              </div>
              <div className="mt-4 text-gray-500">
                With Towbook, you&apos;ll have access to our entire suite of
                features for an entire month before signing up! No credit card
                on file, no contracts; just you getting to know us and our
                software.
              </div>
              <Waypoint
                onEnter={() => stepsToggle(true)}
                bottomOffset="4%"
              ></Waypoint>
            </animated.div>
          </div>

          <div className="flex-1 mt-6 lg:mt-0 lg:ml-16">
            <animated.div style={stepsTrail[1]}>
              <ImportBusiness className="w-16 h-16 mb-4 text-blue-500 lg:mb-8 lg:mx-auto lg:w-24 lg:h-24"></ImportBusiness>
              <div className="text-xl font-600">
                We&apos;ll help you get setup
              </div>
              <div className="mt-4 text-gray-500">
                Setup is simple! After signing up for your free trial,
                you&apos;ll have access to our quick and easy onboarding
                experience, and our 24/7 customer service! Our team is here
                every step of the way to make sure you and your staff are
                utilizing Towbook to its full potential and to answer any
                questions too!
              </div>
            </animated.div>
          </div>

          <div className="flex-1 mt-6 lg:ml-16 lg:mt-0">
            <animated.div style={stepsTrail[2]}>
              <DispatchCall className="w-16 h-16 mb-4 text-blue-500 lg:mb-8 lg:mx-auto lg:w-24 lg:h-24"></DispatchCall>
              <div className="text-xl font-600">Dispatch your first call</div>
              <div className="mt-4 text-gray-500">
                Once setup, you&apos;ll be ready to start using Towbook for your
                business! Send calls to drivers, view their location on a map,
                directly bill your motor clubs, provide your customers with
                automated updates about their service, create reports, and so
                much more!
              </div>
            </animated.div>
          </div>
        </div>

        <div className="flex justify-center mt-12">
          <Link
            to="/signup/"
            className="block px-6 py-3 text-lg text-white bg-blue-500 rounded-lg hover:bg-blue-400"
          >
            Get started for free
          </Link>
        </div>
      </ContentBlock>
      <AngleRight className="w-full h-4 text-gray-100 bg-white lg:h-8"></AngleRight>
      <ContentBlock containerClassName="bg-white text-gray-800 py-12 lg:py-24">
        <h3 className="sr-only">Driven by Performance</h3>
        <DrivenByPerformance className="w-full h-auto"></DrivenByPerformance>
        <div className="flex flex-col mt-12 lg:flex-row lg:mt-24 lg:items-center">
          <div className="mb-8 lg:mb-0 lg:-ml-12 lg:w-3/5">
            <img
              src="/screenshots/screenshot-mac.png"
              alt="Towbook web app"
              className="w-4/5 mx-auto lg:w-full"
            />
          </div>
          <div className="flex-1 lg:ml-12">
            <div className="text-2xl lg:text-3xl font-600 leading-300">
              Manage your business from anywhere
            </div>
            <div className="mt-4 mb-6 text-gray-700 lg:text-lg">
              In the office using a computer or on-the-go using your mobile app,
              we&apos;ve got you covered! Our cloud-based software was built for
              owners, managers, drivers, and dispatchers who live a busy
              lifestyle and need access from wherever they are.
            </div>
            <div className="flex">
              <Link
                to="/signup/"
                className="block text-lg text-blue-500 underline"
              >
                Get started for free
              </Link>
            </div>
          </div>
        </div>
      </ContentBlock>
      <AngleRight className="w-full h-4 text-white bg-gray-800 lg:h-8"></AngleRight>
      <ContentBlock containerClassName="bg-gray-800 text-white py-12 lg:py-24 overflow-hidden">
        <div className="max-w-4xl mx-auto text-3xl text-center lg:text-5xl font-700 leading-300">
          Feature Rich
        </div>
        <div className="mt-2 mb-16 text-xl text-center text-gray-200 lg:text-2xl font-500">
          (and we&apos;re not slowing down)
        </div>
        {featureGroups.map(({ name, description, media }, index) => (
          <IndexFeatureRow
            key={index}
            index={index}
            name={name}
            description={description}
            media={media}
          ></IndexFeatureRow>
        ))}
        <div className="flex justify-center">
          <Link
            to="/features/"
            className="block px-6 py-3 text-lg text-gray-800 bg-yellow-500 rounded hover:bg-yellow-400 font-400"
          >
            View All Features
          </Link>
        </div>
      </ContentBlock>
      <AngleRight className="w-full h-4 text-gray-800 bg-white lg:h-8"></AngleRight>
      <ContentBlock
        containerClassName="bg-white text-gray-800"
        className="py-12 lg:py-24"
      >
        <h3 className="sr-only">Your Office Without Walls</h3>
        <WithoutWalls className="w-full h-auto mb-10 fill-current lg:mb-20"></WithoutWalls>
        <div className="flex flex-col-reverse lg:flex-row lg:items-center">
          <div className="flex-1">
            <div className="text-2xl lg:text-3xl font-600 leading-300">
              The information age is upon us and you need to be able to work
              from anywhere.
            </div>
            <div className="mt-4 mb-8 text-gray-700 lg:text-lg">
              Towbook understands. Use the Towbook platform from any device and
              on as many devices as you want. We don&apos;t charge higher rates
              for additional users. Windows, Mac, iPhone OS & Android
              applications are continually being developed and updated as
              technology grows around us.
            </div>
            <div className="flex justify-center lg:justify-start">
              <a href="https://apps.apple.com/us/app/towbook/id670258292">
                <img src="/app-store.svg" alt="App Store" className="h-12" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.towbook.mobile"
                className="ml-4"
              >
                <img
                  src="/play-store.svg"
                  alt="Google Play Store"
                  className="h-12"
                />
              </a>
            </div>
          </div>
          <div className="relative z-10 w-2/5 mx-auto mb-6 lg:ml-12 lg:-mb-48">
            <img
              src="/screenshots/screenshot-ios.png"
              alt="Towbook active calls on the iPhone app"
            />
          </div>
        </div>
      </ContentBlock>
      <AngleRight className="w-full h-4 text-white bg-gray-100 lg:h-8"></AngleRight>
      <ContentBlock containerClassName="bg-gray-100 text-gray-800 py-16 lg:py-32">
        <div className="flex flex-col-reverse items-center md:flex-row">
          <div className="flex-1 mt-12 md:mt-0">
            <div className="text-2xl md:text-3xl font-600 leading-300">
              Your business is all about customer service, so is ours. We take
              great pride in having the best support in the industry.
            </div>
            <div className="mt-4 text-gray-700 md:text-md">
              We answer our phones 24 hours a day, 7 days a week, no exceptions.
              Call us any time to see how helpful our staff is – from simple
              sales questions to technical support, we&apos;re always here and
              ready to assist.
            </div>
          </div>
          <div className="w-2/3 md:ml-10 md:pl-10 md:w-1/3">
            <div className="sr-only">24/7 No Exceptions.</div>
            <Icon247 className="w-full"></Icon247>
          </div>
        </div>
      </ContentBlock>
      <AngleRight className="w-full h-4 text-gray-100 bg-gray-800 lg:h-8"></AngleRight>
      <ContentBlock
        containerClassName="bg-gray-800 text-gray-100"
        className="py-12 lg:pt-24 lg:pb-12"
      >
        <div className="max-w-4xl mx-auto text-3xl text-center text-white lg:text-5xl font-700 leading-300">
          Don&apos;t take our word for it!
        </div>

        <div className="flex flex-col mt-12 lg:flex-row-reverse lg:items-center">
          <div className="w-full lg:w-3/5">
            <div className="aspect-16/9">
              <iframe
                src="https://www.youtube.com/embed/mSH1tAsI3eE"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Towbook Testimonial"
              ></iframe>
            </div>
          </div>

          <div className="mt-6 lg:w-2/5 lg:mt-0 lg:mr-12">
            <div className="relative text-3xl italic lg:text-4xl font-200 leading-300">
              <span className="top-0 left-0 mr-1 text-yellow-500 lg:absolute lg:mr-0 lg:-ml-4">
                &ldquo;
              </span>
              It makes work, life, everything, much much easier. Towbook is a
              must!
              <span className="text-yellow-500">&rdquo;</span>
            </div>
            <div className="mt-4 text-xl lg:text-2xl leading-100 lg:mt-8">
              Venus
            </div>
            <div className="italic text-gray-300 lg:text-lg">Rock Towing</div>
          </div>
        </div>

        <div className="flex flex-col mt-12 lg:flex-row lg:items-center">
          <div className="w-full lg:w-3/5">
            <div className="aspect-16/9">
              <iframe
                src="https://www.youtube.com/embed/DebtJjbp6KA"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Towbook Testimonial"
              ></iframe>
            </div>
          </div>

          <div className="mt-6 lg:w-2/5 lg:mt-0 lg:ml-12">
            <div className="relative text-3xl italic lg:text-4xl font-200 leading-300">
              <span className="top-0 left-0 mr-1 text-yellow-500 lg:absolute lg:mr-0 lg:-ml-4">
                &ldquo;
              </span>
              We love how Towbook constantly updates to improve and run our
              business more efficiently.
              <span className="text-yellow-500">&rdquo;</span>
            </div>
            <div className="mt-4 text-xl lg:text-2xl leading-100 lg:mt-8">
              Diane
            </div>
            <div className="italic text-gray-300 lg:text-lg">
              Trinity Transport Towing, LLC
            </div>
          </div>
        </div>
      </ContentBlock>
    </Layout>
  )
}

export default PageIndex
